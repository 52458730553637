import { IActionInterface } from "../../interfaces";
import { screenActions } from "../actions/actionTypes";

interface IInitialState {
  publishedTrips: any;
  loadingImages: boolean;
  loading: boolean;
  loadingAllImages: boolean;
  trendingDestinationsList: any;
  publishedTripsLoader: boolean;
  trendingDestinationsListLoader: boolean;
  featuredUsers: any;
  featuredUsersLoader: boolean;
  longTrips: any;
  longTripsLoader: boolean;
  itineraryDetailsTrips: any;
  itineraryDetailsTripsLoader: any;
  weekendGatewaysLoader: boolean;
  weekendGateways: any;
  searchItenairyList: any;
  searchItenairyLoader: boolean;
  featuredUserDetail: any;
  featuredUserDetailLoader: boolean;
  featuredUserTripsList: any;
  searchLoading: boolean;
  searchUserList: any;
  searchDestinationList: any;
  userDetails: any;
  placeDetails: any;
  loadingDestination: boolean;
}

const initialState: IInitialState = {
  publishedTrips: {},
  publishedTripsLoader: false,
  loadingImages: false,
  loading: false,
  loadingAllImages: false,
  trendingDestinationsList: [],
  trendingDestinationsListLoader: false,
  featuredUsersLoader: false,
  featuredUsers: [],
  longTrips: [],
  longTripsLoader: false,
  itineraryDetailsTrips: {},
  itineraryDetailsTripsLoader: false,
  weekendGatewaysLoader: false,
  weekendGateways: [],
  searchItenairyList: [],
  searchItenairyLoader: false,
  featuredUserDetail: [],
  featuredUserDetailLoader: false,
  featuredUserTripsList: [],
  searchUserList: [],
  searchLoading: false,
  searchDestinationList: [],
  userDetails: [],
  placeDetails: {},
  loadingDestination: false,
};

const screenReducer = (state = initialState, action: IActionInterface) => {
  const { type, payload } = action;
  switch (type) {
    case screenActions.GET_PUBLISHED_TRIP_REQUESTED:
      return {
        ...state,
        publishedTrips: {},
        publishedTripsLoader: true,
      };
    case screenActions.GET_PUBLISHED_TRIP_SUCCEEDED:
      return {
        ...state,
        publishedTrips: payload,
        publishedTripsLoader: false,
      };

    case screenActions.GET_PUBLISHED_TRIP_FAILED:
      return {
        ...state,
        publishedTrips: {},
        publishedTripsLoader: false,
      };

    case screenActions.GET_IMAGES_REQUESTED:
      return {
        ...state,
      };
    case screenActions.GET_IMAGES_FAILED:
      return {
        ...state,
      };
    case screenActions.GET_IMAGES_SUCCEEDED:
      return {
        ...state,
      };
    case screenActions.GET_ALL_IMAGES_REQUESTED:
      return {
        ...state,
        loadingAllImages: true,
      };
    case screenActions.GET_ALL_IMAGES_FAILED:
      return {
        ...state,
        loadingAllImages: false,
      };
    case screenActions.GET_ALL_IMAGES_SUCCEEDED:
      return {
        ...state,
        loadingAllImages: false,
      };

    case screenActions.GET_TRENDING_DESTINATIONS_REQUESTED:
      return {
        ...state,
        trendingDestinationsListLoader: true,
        trendingDestinationsList: [],
      };

    case screenActions?.GET_TRENDING_DESTINATIONS_SUCCESS:
      return {
        ...state,
        trendingDestinationsListLoader: false,
        trendingDestinationsList: payload,
      };

    case screenActions.GET_TRENDING_DESTINATIONS_FAILED:
      return {
        ...state,
        trendingDestinationsListLoader: false,
        trendingDestinationsList: [],
      };

    case screenActions.GET_FEATURED_USERS_REQUESTED:
      return {
        ...state,
        featuredUsers: [],
        featuredUsersLoader: true,
      };

    case screenActions.GET_FEATURED_USERS_SUCCESS:
      return {
        ...state,
        featuredUsers: payload,
        featuredUsersLoader: false,
      };

    case screenActions.GET_FEATURED_USERS_FAILED:
      return {
        ...state,
        featuredUsers: {},
        featuredUsersLoader: false,
      };

    case screenActions.GET_ITINERARY_DETAILS_REQUESTED:
      return {
        ...state,
        itineraryDetailsTrips: {},
        itineraryDetailsTripsLoader: true,
      };

    case screenActions.GET_ITINERARY_DETAILS_SUCCESS:
      return {
        ...state,
        itineraryDetailsTrips: payload,
        itineraryDetailsTripsLoader: false,
      };

    case screenActions.GET_ITINERARY_DETAILS_FAILED:
      return {
        ...state,
        itineraryDetailsTrips: {},
        itineraryDetailsTripsLoader: false,
      };

    case screenActions.GET_FEATURED_USER_DETAILS_REQUESTED:
      return {
        ...state,
        featuredUserDetail: [],
        featuredUserDetailLoader: true,
      };

    case screenActions.GET_FEATURED_USER_DETAILS_SUCCESS:
      return {
        ...state,
        featuredUserDetail: payload,
        featuredUserDetailLoader: false,
      };

    case screenActions.GET_FEATURED_USER_DETAILS_FAILED:
      return {
        ...state,
        featuredUserDetail: [],
        featuredUserDetailLoader: false,
      };

    case screenActions.GET_FEATURED_USER_PUBLISHED_TRIPS_REQUESTED:
      return {
        ...state,
        featuredUserTripsList: [],
        featuredUserDetailLoader: true,
      };

    case screenActions.GET_FEATURED_USER_PUBLISHED_TRIPS_SUCCESS:
      return {
        ...state,
        featuredUserTripsList: payload,
        featuredUserDetailLoader: false,
      };

    case screenActions.GET_FEATURED_USER_PUBLISHED_TRIPS_FAILED:
      return {
        ...state,
        featuredUserTripsList: [],
        featuredUserDetailLoader: false,
      };

    case screenActions.GET_SEARCHED_ITINERARY_REQUESTED:
      return {
        ...state,
        searchItenairyList: [],
        searchItenairyLoader: true,
      };

    case screenActions.GET_SEARCHED_ITINERARY_SUCCESS:
      return {
        ...state,
        searchItenairyList: payload,
        searchItenairyLoader: false,
      };

    case screenActions.GET_SEARCHED_ITINERARY_FAILED:
      return {
        ...state,
        searchItenairyList: [],
        searchItenairyLoader: false,
      };

    case screenActions.GET_SEARCHED_USERS_REQUESTED:
      return {
        ...state,
      };

    case screenActions.GET_SEARCHED_USERS_SUCCESS:
      return {
        ...state,
      };

    case screenActions.GET_SEARCHED_USERS_FAILED:
      return {
        ...state,
      };

    case screenActions.GET_POPULAR_TRIPS_REQUESTED:
      return {
        ...state,
      };

    case screenActions.GET_POPULAR_TRIPS_SUCCESS:
      return {
        ...state,
      };

    case screenActions.GET_POPULAR_TRIPS_FAILED:
      return {
        ...state,
      };

    case screenActions.GET_WEEKENDS_GATEWAY_REQUESTED:
      return {
        ...state,
        weekendGateways: [],
        weekendGatewaysLoader: true,
      };

    case screenActions.GET_WEEKENDS_GATEWAY_SUCCESS:
      return {
        ...state,
        weekendGateWays: payload,
        weekendGatewaysLoader: false,
      };

    case screenActions.GET_WEEKENDS_GATEWAY_FAILED:
      return {
        ...state,
        weekendGateways: [],
        weekendGatewaysLoader: false,
      };

    case screenActions.GET_LONG_TRIPS_REQUESTED:
      return {
        ...state,
        longTrips: [],
        longTripsLoader: true,
      };

    case screenActions.GET_LONG_TRIPS_SUCCESS:
      return {
        ...state,
        longTrips: payload,
        longTripsLoader: false,
      };

    case screenActions.GET_LONG_TRIPS_FAILED:
      return {
        ...state,
        longTrips: [],
        longTripsLoader: false,
      };

    case screenActions.GET_SORTED_ITINERARY_REQUESTED:
      return {
        ...state,
      };

    case screenActions.GET_SORTED_ITINERARY_SUCCESS:
      return {
        ...state,
      };

    case screenActions.GET_SORTED_ITINERARY_FAILED:
      return {
        ...state,
      };

    case screenActions.GET_PLACE_DETAILS_REQUESTED:
      return {
        ...state,
        placeDetails: {},
        loadingDestination: true,
      };

    case screenActions.GET_PLACE_DETAILS_SUCCESS:
      return {
        ...state,
        placeDetails: payload,
        loadingDestination: false,
      };

    case screenActions.GET_PLACE_DETAILS_FAILED:
      return {
        ...state,
        placeDetails: {},
        loadingDestination: false,
      };

    case screenActions.GET_COORDINATES_REQUESTED:
      return {
        ...state,
      };

    case screenActions.GET_COORDINATES_SUCCESS:
      return {
        ...state,
      };

    case screenActions.GET_COORDINATES_FAILED:
      return {
        ...state,
      };

    case screenActions.SEARCH_USERS_REQUESTED:
      return {
        ...state,
        searchLoading: true,
        searchUserList: [],
      };
    case screenActions.SEARCH_USERS_SUCCESS:
      return {
        ...state,
        searchUserList: payload,
        searchLoading: false,
      };
    case screenActions.SEARCH_USERS_FAILED:
      return {
        ...state,
        searchLoading: false,
      };

    case screenActions.GET_SEARCHED_DESTINATIONS_REQUESTED:
      return {
        ...state,
        searchLoading: true,
        searchDestinationList: [],
      };
    case screenActions.GET_SEARCHED_DESTINATIONS_SUCCESS:
      return {
        ...state,
        searchDestinationList: payload,
        searchLoading: false,
      };
    case screenActions.GET_SEARCHED_DESTINATIONS_FAILED:
      return {
        ...state,
        searchLoading: false,
        searchDestinationList: [],
      };

    case screenActions.GET_USER_DETAILS_REQUESTED:
      return {
        ...state,
        userDetails: [],
      };
    case screenActions.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: payload,
      };
    case screenActions.GET_USER_DETAILS_FAILED:
      return {
        ...state,
        userDetails: [],
      };

    default:
      return { ...state };
  }
};

export default screenReducer;
